<template>
    <div class="bg-page">
        <van-nav-bar :border="false"
                     :placeholder="true"
                     :fixed="true"
                     :title="$t('提款方式')"
                     left-arrow
                     @click-left="onClickLeft"
                     @click-right="onClickRight"
        >
            <template #right>
                <div class="plus-icon"><van-icon name="plus" color="#ffffff" /></div>
            </template>
        </van-nav-bar>
        <div class="wrapper">
            <div class="quk-card">
                <div class="quk-item">
                    <div class="value">{{user.balance}} <span class="text-gray" >{{$t('USDT')}}</span></div>
                    <div class="label">{{$t('钱包余额')}}</div>
                </div>
                <!-- <div class="quk-item text-right">
                    <div class="value">{{user.huilv_withdraw}}</div>
                    <div class="label">{{$t('汇率')}}</div>
                </div> -->
            </div>
            <div class="wt-panel bs-panel">
                <div class="wt-input_items">
                    <div class="hd">{{$t('金额')}} <span class="text-gray" style="font-size: 12px">( {{$t('USDT')}} )</span></div>
                    <van-field v-model="money" type="number" :placeholder="$t('请输入提现金额')" />
                </div>
                <div class="tri-cellss" style="color: #009d64" v-if="radio == 'BANK' && money > 0">
                    {{$t('到账金额')}} : {{parseFloat(money * user.huilv_withdraw).toFixed(2)}}
                    <span style="font-size: 12px"> {{$t('USDT')}}</span>
                </div>
                <van-skeleton title :row="3" :loading="loading"></van-skeleton>
                <template v-if="!loading">
                    <div class="tri-cellss" v-html="$t('提现说明提示')"></div>
                    <van-radio-group v-model="radio">
                        <van-cell-group>
                            <van-cell v-if="withdraw_info.usdt == '1' && isBandTRC20" center :title="$t('USDT（TRC20）')"
                                        :label="$t('强烈推荐TRC20，免手续费')" clickable
                                        @click="radio = 'TRC20'">
                                <template #icon>
                                    <img src="../../assets/pictures/USDT.png" class="wt-icon" alt="">
                                </template>
                                <template #right-icon>
                                    <van-radio name="TRC20">
                                        <template #icon="props">
                                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                            <van-cell v-if="withdraw_info.usdt == '1' && isBandERC20" center :title="$t('USDT（ERC20）')"
                                        :label="$t('ERC20 TIPS')"
                                        clickable
                                        @click="radio = 'ERC20'">
                                <template #icon>
                                    <img src="../../assets/pictures/USDT.png" class="wt-icon" alt="">
                                </template>
                                <template #right-icon>
                                    <van-radio name="ERC20">
                                        <template #icon="props">
                                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                            <van-cell center :title="$t('银行提款')" clickable @click="radio = 'BANK'" :label="$t('bank withdraw')" v-if="isBandBank">
                                <template #icon>
                                    <img src="../../assets/pictures/bank-icon.png" class="wt-icon" alt="">
                                </template>
                                <template #right-icon>
                                    <van-radio name="BANK">
                                        <template #icon="props">
                                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </template>
            </div>
            <div class="wt-tips">
                <div class="wt-label text-green">{{$t('提示')}}</div>
                <div class="wt-body text-gray" v-if="this.$t('提现文字说明') != '提现文字说明'" v-html="$t('提现文字说明')"></div>
                <div class="wt-body text-gray" v-if="this.$t('提现文字说明2') != '提现文字说明2'" v-html="$t('提现文字说明2')"></div>
                <div class="wt-body text-gray" v-if="this.$t('提现文字说明3') != '提现文字说明3'" v-html="$t('提现文字说明3')"></div>
                <div class="wt-body text-gray" v-if="this.$t('提现文字说明4') != '提现文字说明4'" v-html="$t('提现文字说明4')"></div>
            </div>
            <div class="btn-space" style="padding-top: 20px">
                <van-button type="primary" block @click="handleClickOk">{{$t('确认')}}</van-button>
            </div>
        </div>

        <!-- 弹窗 -->
        <van-popup class="page-popup" v-model="show2" position="bottom" closeable
                   :style="{ borderRadius: '10px'}">
            <div class="wt-dialog">
                <div class="wt-top">{{$t('选择取款方式')}}</div>
                <div class="wt-panel">
                    <van-radio-group v-model="add_radio">
                        <van-cell v-if="withdraw_info.usdt == '1'" center :title="$t('USDT（TRC20）')"
                                  :label="$t('强烈推荐TRC20，免手续费')" clickable
                                  @click="add_radio = 'TRC20'">
                            <template #icon>
                                <img src="../../assets/pictures/USDT.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="TRC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeCheck : inactiveCheck"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell v-if="withdraw_info.usdt == '1'" center :title="$t('USDT（ERC20）')"
                                  clickable
                                  @click="add_radio = 'ERC20'">
                            <template #icon>
                                <img src="../../assets/pictures/USDT.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="ERC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeCheck : inactiveCheck"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell center :title="$t('银行提款')" clickable @click="add_radio = 'BANK'">
                            <template #icon>
                                <img src="../../assets/pictures/bank-icon.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="BANK">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeCheck : inactiveCheck"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </van-radio-group>
                    <div class="popbtn">
                        <van-row :gutter="10">
                            <van-col :span='12'><van-button type="warning" class="nfobtn" color="#fff" plain size="normal" @click="show2 = false">{{$t('取消')}}</van-button></van-col>
                            <van-col :span='12'><van-button type="primary" size="normal" @click="toBind">{{$t('确定')}}</van-button></van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>


        <van-popup  class="bs-pop" v-model="show3" position="bottom" closeable>
            <div class="bs-popup-dialog">
                <div class="bs-popup-head">{{$t('设置交易密码')}}</div>
                <div class="wt-dialog_bd">
                    <div style="font-size: 14px;padding: 20px">
                        {{$t('您还没有设置交易密码，是否继续操作？')}}
                    </div>
                    <div class="popbtn" style="margin-top:10px;">
                        <van-row :gutter="24">
                            <van-col :span="12">
                                <van-button @click="handleClickOk2" type="gray">{{$t('继续')}}</van-button>
                            </van-col>
                            <van-col :span="12">
                                <van-button to="/pay-set" type="primary">{{$t('设置')}}</van-button>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup  class="bs-pop" v-model="show4" position="bottom" closeable>
            <div class="bs-popup-dialog">
                <div class="bs-popup-head">{{$t('交易密码')}}</div>
                <div class="wt-dialog_bd">
                    <div class="bs-form">
                        <van-field v-model="pay_password" class="pay-password" :placeholder="$t('请输入交易密码')" type="password" maxlength="6" />
                    </div>
                    <div class="popbtn" style="margin-top:10px;">
                        <van-row :gutter="24">
                            <van-col :span="12">
                                <van-button @click="show4 = false" type="gray" >{{$t('取消')}}</van-button>
                            </van-col>
                            <van-col :span="12">
                                <van-button @click="handleClickOk2" type="primary">{{$t('确认')}}</van-button>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                radio: 'TRC20',
                add_radio: 'TRC20',
                user: {},
                bank_list: [],
                withdraw_info: {},
                show: false,
                show2: false,
                show3: false,
                show4: false,
                pay_password: '',
                bank_id: 0,
                money: '',
                bank_code: '',
                activeIcon: require('../../assets/pictures/duigouxiao.png'),
                inactiveIcon: require('../../assets/pictures/duigouxiao2.png'),
                activeCheck: require('../../assets/pictures/duigouxiao.png'),
                inactiveCheck: require('../../assets/pictures/duigouxiao2.png'),
                loading:true,
                feeRate:0.002,
                isBandERC20:false,
                isBandTRC20:false,
                isBandBank:false,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onClickRight() {
                this.$router.push({path: '/bank', query: {channel: this.add_radio}});
            },
            toBind() {
                if (this.add_radio == 'BANK') {
                    this.$router.push({path: '/bank-bind',});
                } else if (this.add_radio == 'PIX'){
                    this.$router.push({path: '/pix-bind',});
                } else {
                    this.$router.push({path: '/bind-wallet', query: {channel: this.add_radio}});
                }
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=-1&tag=list').then((responese) => {
                    let res = responese.data
                    this.bank_list = res.data.info;
                    this.bank_list.forEach((item) => {
                        if (item.bank_name === 'TRC20') {
                            this.isBandTRC20 = true
                        } else if (item.bank_name === 'ERC20'){
                            this.isBandERC20 = true
                        }else if (item.type === 0){
                            this.isBandBank = true
                        }
                    });
                    this.loading = false;
                    console.log(this.isBandTRC20)
                    console.log(this.isBandERC20)
                    console.log(this.isBandBank)

                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            getUser() {
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user = res.data;
                    })
            },
            handleClickOk() {
                console.log(this.bank_id)
                if (this.bank_list != undefined && this.bank_list.length > 0) {
                    let bool = false;
                    this.bank_list.forEach((item) => {
                        if (item.type == 1 && item.bank_name == this.radio) {
                            bool = true;
                            this.bank_id = item.id;
                        }
                        if (item.type == 0 && this.radio == 'BANK') {
                            bool = true;
                            this.bank_id = item.id;
                        }
                        if (item.type == 2 && this.radio == 'PIX') {
                            bool = true;
                            this.bank_id = item.id;
                        }
                    });
                } else {
                    this.$toast.fail(this.$t('请先绑定提现渠道'));
                    setTimeout( () => {
                        this.$router.push({path: '/bind-wallet', query: {channel: this.add_radio}});
                        },
                        2000
                    );
                    return;
                }

                if (this.money <= 0) {
                    this.$toast.fail(this.$t('请输入正确的提现金额'));
                    return;
                }
                if (this.money < parseInt(this.withdraw_info.money)) {
                    this.$toast.fail(this.$t('最低提现金额为：') + this.withdraw_info.money);
                    return;
                }
                if (this.money > parseInt(this.withdraw_info.max_money)) {
                    this.$toast.fail(this.$t('最高提现金额为：') + this.withdraw_info.max_money);
                    return;
                }

                if(this.withdraw_info.pay_flag == 1){
                    this.pay_password = '';
                    this.show4 = true;
                }else{
                    this.show3 = true;
                }
            },
            handleClickOk2() {
                if (this.bank_list != undefined && this.bank_list.length > 0) {
                    let bool = false;
                    this.bank_list.forEach((item) => {
                        if (item.type == 1 && item.bank_name == this.radio) {
                            bool = true;
                            this.bank_id = item.id;
                        }
                        if (item.type == 0 && this.radio == 'BANK') {
                            bool = true;
                            this.bank_id = item.id;
                        }
                    });
                    if (!bool) {
                        if (this.withdraw_info.usdt) {
                            this.show2 = true;
                        }else{
                            this.show = true;
                        }
                        this.$toast.fail(this.$t('请先绑定提现渠道'));
                        return;
                    }
                } else {
                    if (this.withdraw_info.usdt) {
                        this.show2 = true;
                    }else{
                        this.show = true;
                    }
                    this.$toast.fail(this.$t('请先绑定提现渠道'));
                    return;
                }

                if (this.money <= 0) {
                    this.$toast.fail(this.$t('请输入正确的提现金额'));
                    return;
                }

                if(this.withdraw_info.pay_flag == 1 && this.pay_password == ''){
                    this.$toast.fail(this.$t('请输入交易密码'));
                    return;
                }

                if (this.money < parseInt(this.withdraw_info.money)) {
                    this.$toast.fail(this.$t('最低提现金额为：') + this.withdraw_info.money);
                    return;
                }
                if (this.money > parseInt(this.withdraw_info.max_money)) {
                    this.$toast.fail(this.$t('最高提现金额为：') + this.withdraw_info.max_money);
                    return;
                }
                if (!this.bank_id) {
                    this.$toast.fail(this.$t('请选择提款银行卡'));
                    return;
                }
                if (this.money) {
                    let loading = this.$toast.loading();
                    // if(this.money<1){
                    //     this.money = 1
                    // }else{
                    //     this.money =  parseFloat(this.money * (1 - this.feeRate)).toFixed(2)
                    // }
                    console.log(this.money)
                    this.$axios
                        .post(
                            this.$api_url.withdraw_add,
                            this.$qs.stringify({
                                money: this.money,
                                bank_id: this.bank_id,
                                pay_password: this.pay_password
                            })
                        )
                        .then((res) => {
                            if (res.data.code === 0) {
                                this.show4 = false;
                                this.$toast.success(res.data.msg);
                                this.$router.push({path: '/withdraw-record'});
                            } else {
                                this.$toast.fail(res.data.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.fail(err.message);
                        });
                }
            },
            getWithdrawInfo() {
                this.$axios
                    .get(this.$api_url.withdraw_info)
                    .then((res) => {
                        this.withdraw_info = res.data;
                    })
            }
        },
        mounted() {
            this.getUser();
            this.getData();
            this.getWithdrawInfo();
        }

    }
</script>
<style scoped="scoped">
    .tri-cellss {
        font-size: 14px;
        padding: 0 0 20px 0;
    }
    .wt-tips{
        padding: 5px;
        font-size: 14px;
    }
    .wt-tips .wt-label{
        margin-bottom: 6px;
    }

</style>
<style scoped>
    .wt-body{
        margin-bottom: 6px;
    }
    .bs-pop{
        border-radius: 10px 10px 0 0;
    }

    .bank-pop {
        background: #fff;
        /*padding: 20px 0;*/
        position: relative;
        height: 100%;
        border-radius: 10px 10px 0 0;
        padding-bottom: 20px;

    }
</style>